@use 'styles/functions' as fn;
@use 'styles/variables' as var;

.tcp-banner {
  z-index: 10;
  background-color: var.$color-neutral-0;
  box-shadow: 0 6px 40px -4px rgb(16 24 40 / 11%), 0 9px 12px -3px rgb(16 24 40 / 5%);
}

.button-padding {
  padding-right: fn.spacing(2);
  padding-left: fn.spacing(2);
}

.tcp-banner[data-position-top='true'] {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.tcp-banner[data-position-top='false'] {
  max-width: var.$maximum-site-content-width;
  position: fixed;
  bottom: 0;
}

.tcp-content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: fn.spacing(1);
  padding: fn.spacing(2) fn.spacing(1.5);
}

.tcp-btn {
  flex: 1;
}

.tpc-msg {
  flex: 2;
}

.tcp-x {
  cursor: pointer;
  position: absolute;
  top: fn.spacing(1);
  right: fn.spacing(1.5);
}

@media screen and (min-width: var.$breakpoint-lg) {
  .tcp-content {
    gap: fn.spacing(4);
    padding: fn.spacing(4) fn.spacing(6);
  }

  .tcp-btn {
    margin-top: 0;
  }
}

@media screen and (min-width: '940px') and (max-width: var.$breakpoint-lg) {
  .tcp-btn {
    margin-top: fn.spacing(1.5);
  }
}
